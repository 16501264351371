import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import ViewBank from './pages/ViewBank';
import SponsorForm from './pages/SponsorForm';
import AddBank from './pages/AddBank';
import BookCollection from './pages/BookCollection';
import Account from './pages/Account';
import EditBank from './pages/EditBank';
import BehindBars from './pages/BehindBars';
import NearestBanks from './pages/NearestBanks';
import GeoQueryTest from './pages/GeoQueryTest';
import ThankYouSponsor from './pages/ThankYouSponsor';
import Campaign from './pages/ts-cs/Campaign';
import SingleTile from './pages/ts-cs/SingleTile';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import frlogo from './assets/images/frlogo.png';

const App = () => {

  return (
    <>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<ViewBank />} />
          <Route path="/login" element={ <Login /> } />
          <Route path="/account" element={ <Account /> } />
          <Route path="/geo-test" element={ <SponsorForm /> } />
          <Route path="/bank" element={ <ViewBank /> } /> 
          <Route path="/add-bank" element={ <AddBank /> } />
          <Route path="/edit-bank" element={ <EditBank /> } />
          <Route path="/book-collection" element={ <BookCollection /> } />
          <Route path="/bank-behind-bars" element={ <BehindBars /> } />
          <Route path="/nearest" element={ <NearestBanks /> } />
          <Route path="/sponsor" element={ <SponsorForm /> } />
          <Route path="/sponsor-campaign" element={ <GeoQueryTest /> } />
          <Route path="/sponsor/thank-you" element={ <ThankYouSponsor /> } />
          <Route path="/sponsor-tc-single" element={ <SingleTile /> } />
          <Route path="/sponsor-tc-campaign" element={ <Campaign /> } />
        </Routes>
    </BrowserRouter>
    <FloatingWhatsApp phoneNumber='447453958291' accountName='Fundraise & Recycle' avatar={frlogo} />
    </>
  );
}

export default App;