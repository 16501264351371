import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Header from '../components/Header';
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { db, storage } from '../util/firebaseApp';
import emailjs from 'emailjs-com';
import { useSearchParams } from 'react-router-dom';

const SponsorForm = () => {
    const [searchParams] = useSearchParams();
    const [formStatus, setFormStatus] = useState('Send');
    const [tcUrl, setTCUrl] = useState('');
    const [bank, setBank] = useState({});
    const [paypalButtonStatus, setPaypalButtonStatus] = useState(false);
    const [sponsorFor, setSponsorFor] = useState('');
    const [productCode, setProductCode] = useState("");
    const [sponsorPrices, setSponsorPrices] = useState([]);
    const tile12 = "https://buy.stripe.com/dR62ajdKy1GgeBO002";
    const tile6 = "https://buy.stripe.com/8wMbKTdKyckUctG9AA";
    const onSubmit = async (e) => {
        e.preventDefault();
        setFormStatus("Submitting...");
        const { name, email, phone, link, acceptTick, image, adlength, hasVideo } = e.target.elements;

        if (!acceptTick.checked) {
            alert("You must accept the terms and conditions to continue.");
            return;
        }

        if (!image.files[0]) {
            alert("An image has not been uploaded");
            return;
        }

        if (!adlength.value) {
            alert("Please select a length of advert.");
            return;
        }

        const imageRef = ref(storage, `sponsor-images/${image.files[0].name}`);
        let sponForm = {
            name: name.value,
            email: email.value,
            phone: phone.value, 
            link: link.value,
            acceptTick: acceptTick.value,
            image: imageRef.fullPath,
            sponsor: sponsorFor,
            hasVideo: hasVideo.value,
            adlength: adlength.value
        }

        uploadBytes(imageRef, image.files[0]).then((snapshot) => {
            console.log("Uploaded image");
        })

        await setDoc(doc(db, 'sponsorRequests', phone.value), sponForm);

        emailjs.sendForm('service_carxj5m', 'template_a0czwt2', e.target, 'qNipy6J_jiSb8K7-E')
            .then((result) => {
                setFormStatus("Success! Thank you. Please make your payment now by clicking the 'checkout' button.");
                setProductCode(adlength.value === 170 ? tile6 : tile12);
                setPaypalButtonStatus(true);
                alert("Success! Thank you. Please make your payment now by clicking the 'checkout' button below")
            }, (error) => {
                console.log(error.text);
                setFormStatus("Something went wrong, please try again.");
        });

    }

    const fetchTCLink = async () => {
        getDownloadURL(ref(storage, "T&C.pdf"))
        .then((url) => {
            setTCUrl(url);
        })
    }

    const loadBankData = async (bankId) => {
        const docRef = doc(db, "banks", bankId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setBank(docSnap.data());
            setSponsorPrices(docSnap.data().sponsorPrices);
            return docSnap.data();
        }
    }

    useEffect(() => {
        fetchTCLink();
        setSponsorFor(searchParams.get('bankId'));
    }, [])

    useEffect(() => {
        if (sponsorFor !== "") {
            loadBankData(sponsorFor)
        }
    }, [sponsorFor])

    return (
        <PageContainer>
            <Header />
            <PageContent>
                <Title>Sponsor a Clothes Bank</Title>
                <Subtitle>Sponsorship for {bank.name}</Subtitle>
                <Form onSubmit={onSubmit} className="sponsor-form" noValidate>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="name">Name</label>
                        <input className="form-control" type="text" id="name" name="name" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="email">Email Address</label>
                        <input className="form-control" type="text" id="email" name="email" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="phone">Phone Number</label>
                        <input className="form-control" type="text" id="phone" name="phone" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="link">Link for your Advert</label>
                        <input className="form-control" type="text" name="link" id="link" />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="image">Advert Image</label>
                        <input className="form-control" type="file" id="image" />
                    </div>
                    { sponsorPrices && sponsorPrices.length > 1 ? 
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="adlength">Advert Length</label>
                        <div id="adlength">
                            <div className="radio-option-container">
                                <input className="form-control radio " type="radio" id="6mths" value={sponsorPrices[0].price} name="adlength" />
                                <label className="form-label radio" htmlFor="6mths">{sponsorPrices[0].name} - £{sponsorPrices[0].price}</label> 
                            </div>
                            <div className="radio-option-container">
                                <input className="form-control radio" type="radio" id="12mths" value={sponsorPrices[1].price} name="adlength" />
                                <label className="form-label radio" htmlFor="12mths">{sponsorPrices[1].name} - £{sponsorPrices[1].price}</label>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="adlength">Advert Length</label>
                        <div id="adlength">
                            <div className="radio-option-container">
                                <input className="form-control radio " type="radio" id="6mths" value="204" name="adlength" />
                                <label className="form-label radio" htmlFor="6mths">6 Months- £204</label> 
                            </div>
                            <div className="radio-option-container">
                                <input className="form-control radio" type="radio" id="12mths" value="360" name="adlength" />
                                <label className="form-label radio" htmlFor="12mths">12 Months - £360</label>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="form-field-container checkbox">
                        <label className="form-label" htmlFor="hasVideo">I want to include a video on my advertisement.</label>
                        <input className="form-control" type="checkbox" id="hasVideo" />
                    </div>
                    <div className="form-field-container checkbox">
                        <label className="form-label" htmlFor="acceptTick">I accept the Terms and Conditions laid out <a target="_blank" href={tcUrl}>here</a></label>
                        <input className="form-control" type="checkbox" id="acceptTick" required />
                    </div>
                    {
                        !paypalButtonStatus ?
                        <button className="btn btn-danger" type="submit">
                            {formStatus}
                        </button> :
                        ""
                    }
                    
                    {
                        paypalButtonStatus ?
                        <button onClick={() => {window.location.href = productCode}}>Checkout</button> :
                        ""
                    }
                    
                </Form>
            </PageContent>
        </PageContainer>
    )
}

export default SponsorForm;

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const PageContent = styled.div`
    max-width: 1296px;
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const Title = styled.h1`
    font-size: 48px;
    font-weight: normal;
    padding-left: 18px;
`

const Subtitle = styled.h2`
    text-align: center;
    padding-left: 18px;
`

const Form = styled.form`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 470px;
    width: 100%;
    padding: 24px;
    
    .form-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-top: 24px;
    }

    input {
        box-sizing: border-box;
        width: 100%;
        padding: 12px;
    }

    #adPhoto {
        padding: 0;
    }

    label {
        margin-bottom: 8px;
        font-size: 18px;
        box-sizing: border-box;
    }

    button {
        border: 0;
        padding: 8px 24px;
        font-size: 18px;
        background: #AF3D58;
        color: white;
    }

    .form-field-container.checkbox {
        flex-direction: row;
        justify-content: flex-start;
    }

    .form-field-container.checkbox label {
        order: 2;
    }

    .form-field-container.checkbox input {
        order: 1;
    }

    #acceptTick,
    #hasVideo {
        width: auto;
    }

    .radio-option-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin: 4px 0;
    }

    .radio-option-container input.radio {
        width: auto;
        margin-right: 10px;
    }

    .radio-option-container label.radio {
        margin: 0;
        color: black;
        font-size: 14px;
    }

    #adlength {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
`